import Enum from '@/utils/Enum';

const registerTypeList = [{
  value: '1',
  name: '个人注册',
},
{
  value: '2',
  name: '企业邀请注册',
}];

export default new Enum(registerTypeList);
export { registerTypeList as RegisterTypeList };
