<template>
  <div>
    <query-form labelWidth="110px" class="content_block" ref="queryForm" @search="handleSearch" @reset="handleReset">
      <el-form-item label="用户姓名：">
        <el-input placeholder="请输入用户姓名" v-model.trim="query.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input placeholder="请输入手机号" v-model.trim="query.phoneNumber" clearable></el-input>
      </el-form-item>
      <el-form-item class="authedNum" label="关联企业数：">
        <el-input
          placeholder="请输入关联企业数"
          v-model.trim="query.tenantNum"
          clearable
          onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"
        >
          <el-select v-model="query.comparisonOperator" slot="prepend" @change="handleChange">
            <el-option
              v-for="i in comparisonOperatorOptions"
              :key="i.value"
              :label="i.label"
              :value="i.value"
            ></el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <el-form-item label="注册来源：">
        <el-select v-model="query.registerSource" clearable @change="handleChange">
          <el-option v-for="i in RegisterSourceList" :key="i.value" :label="i.name" :value="i.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注册方式：">
        <el-select v-model="query.registerType" clearable @change="handleChange">
          <el-option v-for="i in RegisterTypeList" :key="i.value" :label="i.name" :value="i.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注册起始时间：">
        <el-date-picker v-model="query.startTime" type="datetime" value-format="timestamp" placeholder="注册起始时间" popper-class="xzpt" clearable></el-date-picker>
      </el-form-item>
      <el-form-item label="注册结束时间：">
        <el-date-picker v-model="query.endTime" type="datetime" value-format="timestamp" placeholder="注册结束时间" popper-class="xzpt" clearable ></el-date-picker>
      </el-form-item>
      <el-form-item label="认证状态：">
        <el-select v-model="query.authStatus" clearable @change="handleChange">
          <el-option v-for="i in AuthStatusList" :key="'auth-'+i.value" :label="i.name" :value="i.value"></el-option>
        </el-select>
      </el-form-item>
    </query-form>
    <table-with-pagination
      :tableData="tenantList"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      @size-change="getTableList"
      @current-change="getTableList"
    />
  </div>
</template>
<script>
import { getEmployeeList } from '@/api/tenant';
import TableWithPagination, { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { dateFormat } from '@/utils/dateFormat';
import registerSourceEnum, { RegisterSourceList } from '@/model/xzpt/registerSource';
import AuthEnum, { AuthStatusList } from '@/model/xzpt/auth';
import registerStatusEnum from '@/model/xzpt/registerStatus';
import registerTypeEnum, { RegisterTypeList } from '@/model/xzpt/registerType';

export default {
  name: 'EMPLOYEE_INDEX',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
  },
  data () {
    return {
      query: {
        name: '',
        phoneNumber: '',
        comparisonOperator: 1,
        tenantNum: '',
        registerSource: '',
        authStatus: '',
        registerType: '',
        startTime: '',
        endTime: '',
      },
      comparisonOperatorOptions: [
        { label: '>', value: 1 },
        { label: '<', value: 2 },
        { label: '=', value: 3 },
      ],
      RegisterSourceList,
      RegisterTypeList,
      AuthStatusList,
      tenantList: [],
      columnList: [
        {
          tooltip: true,
          label: '注册来源',
          minWidth: '100px',
          prop: 'registerSource',
          format: v => {
            return registerSourceEnum[v];
          },
        },
        {
          tooltip: true,
          label: '姓名',
          minWidth: '150px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '手机号',
          minWidth: '150px',
          prop: 'phoneNumber',
        },
        {
          tooltip: true,
          label: '关联企业数',
          minWidth: '150px',
          prop: 'tenantNum',
        },
        {
          tooltip: true,
          label: '注册时间',
          minWidth: '150px',
          prop: 'createTime',
          sortable: true,
          format: v => {
            return v ? dateFormat(v).format('YYYY-MM-DD HH:mm:ss') : '--';
          },
        },
        {
          tooltip: true,
          label: '注册方式',
          minWidth: '150px',
          prop: 'registerType',
          format: v => {
            return registerTypeEnum[v];
          },
        },
        {
          tooltip: true,
          label: '认证状态',
          minWidth: '150px',
          prop: 'authenticationStatus',
          format: v => {
            return AuthEnum[v];
          },
        },
        {
          tooltip: true,
          label: '用户状态',
          minWidth: '150px',
          prop: 'registerStatus',
          format: v => {
            return registerStatusEnum[v];
          },
        },
        {
          label: '操作',
          minWidth: '200px',
          fixed: 'right',
          render: (h, { row }) => {
            let actions = [];
            actions.push(
              <span
                class="cursor-pointer text-primary margin-left"
                onClick={this.handleActionClick.bind(this, row, 'check')}
              >
                查看
              </span>,
            );
            return <div>{...actions}</div>;
          },
        },
      ],
    };
  },
  methods: {
    handleActionClick ({ id }, action) {
      if (action === 'check') {
        this.$router.push({
          name: 'XZPT_Person_Detail',
          query: {
            id,
          },
        });
      }
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        name: '',
        phoneNumber: '',
        comparisonOperator: 1,
        tenantNum: '',
        registerSource: '',
        registerType: '',
        startTime: '',
        endTime: '',
      };
      this.handleSearch();
    },
    getTableList () {
      const query = {};

      for (let k in this.query) {
        if (this.query[k]) {
          query[k] = this.query[k];
        }
      }

      let param = {
        ...query,
        orderBy: [this.createTimeSortInfo],
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.currentPage,
      };

      getEmployeeList(param).then(res => {
        this.tenantList = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
    handleChange () {
      this.pagination.currentPage = 1;

      this.getTableList();
    },
  },
};
</script>

<style scoped lang="scss">
.authedNum {
  /deep/ .el-select {
    .el-input {
      height: 30px;
      width: 60px;
      background-color: #fff;
      border-left: 1px solid rgb(220, 223, 230);

      .el-input-group__prepend {
        border: 1px solid rgb(220, 223, 230);
      }
    }
  }
}
</style>
