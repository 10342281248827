import Enum from '@/utils/Enum';

const registerStatusList = [{
  value: 'NORMAL',
  name: '正常',
},
{
  value: 'LOGGED_OUT',
  name: '已注销',
}];

export default new Enum(registerStatusList);
export { registerStatusList as RegisterStatusList };
